import { search as feedSearch } from 'store/feed/thunks';

import { createAsyncThunk } from '../utils';

import type { ISearchParams } from './types';

/**
 * Temporary search implementation
 * Currently done by dispatching the feed search
 */
export const query = createAsyncThunk(
  'search:query',
  async function (params: ISearchParams, api) {
    return api.dispatch(feedSearch(params));
  },
);
