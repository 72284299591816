import { createSlice } from '@reduxjs/toolkit';

import { search } from 'store/feed/thunks';
import { adapter as feedItemsAdapter } from './feed';

import * as thunks from './thunks';

export const searchSlice = createSlice({
  name: 'search',
  initialState: {
    error: false,
    pending: false,
    feed: feedItemsAdapter.getInitialState({
      total: undefined as number | undefined,
      hasNext: undefined as boolean | undefined,
      statuses: {
        fetch: {
          error: false,
          pending: false,
        },
        fetchMore: {
          error: false,
          pending: false,
        },
      },
    }),
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(thunks.query.pending, (state) => {
        state.error = false;
        state.pending = true;
      })
      .addCase(thunks.query.rejected, (state) => {
        state.error = true;
        state.pending = false;
      })
      .addCase(thunks.query.fulfilled, (state) => {
        state.error = false;
        state.pending = false;
      });

    builder
      .addCase(search.pending, (state, action) => {
        const { paging } = action.meta.arg;

        if (paging?.offset) {
          state.feed.statuses.fetchMore.error = false;
          state.feed.statuses.fetchMore.pending = true;
        } else {
          feedItemsAdapter.removeAll(state.feed);
          state.feed.statuses.fetch.error = false;
          state.feed.statuses.fetch.pending = true;
        }
      })
      .addCase(search.rejected, (state, action) => {
        const { paging } = action.meta.arg;

        if (paging?.offset) {
          state.feed.statuses.fetchMore.error = true;
          state.feed.statuses.fetchMore.pending = false;
        } else {
          state.feed.statuses.fetch.error = true;
          state.feed.statuses.fetch.pending = false;
        }
      })
      .addCase(search.fulfilled, (state, action) => {
        const { data } = action.payload;
        const { paging } = action.meta.arg;

        state.feed.total = data.total!;
        state.feed.hasNext = data.hasNext!;

        if (paging?.offset) {
          feedItemsAdapter.addMany(state.feed, data.items ?? []);
          state.feed.statuses.fetchMore = {
            error: false,
            pending: false,
          };
        } else {
          feedItemsAdapter.setAll(state.feed, data.items ?? []);
          state.feed.statuses.fetch = {
            error: false,
            pending: false,
          };
        }
      });
  },
});
